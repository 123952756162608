import React from "react";
import "./reporte-entradas.scss";
import "devextreme/data/odata/store";
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Scrolling,
  Editing,
  Grouping,
  Lookup,
} from "devextreme-react/data-grid";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { useAuth } from "../../contexts/auth";
import { socios, entradas } from "../../api/api";

export default function ReporteEntradas() {
  const { user } = useAuth();
  const userRole = user.user_type;
  const userService = user.service_id;

  if (user) {
    const dataSource = createStore({
      //key: "membership_id",
      loadParams: { serviceid: user.service_id },
      loadUrl: entradas.loadUrl,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };
      },
    });

    if ((userRole === 1) & (userService === null)) {
      // Administrador
      var SociosData = createStore({
        key: "service_id",
        loadUrl: socios.loadUrl,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.headers = {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          };
        },
      });
    } else if ((userRole === 2) & (userService != null)) {
      // Empresa
    } else if ((userRole === 4) & (userService != null)) {
      // Socio
    } else {
      // Sin asignar
    }

    return (
      <React.Fragment>
        <h2 className={"content-block"}>Reporte - Entradas</h2>

        <div className={"content-block dx-card responsive-paddings"}>
          <DataGrid
            id="gridContainer"
            dataSource={dataSource}
            showBorders={true}
            //focusedRowEnabled={true}
            height={600}
            remoteOperations={true}
            allowColumnReordering={true}
            columnAutoWidth={true}
            columnHidingEnabled={true}
          >
            <FilterRow visible={true} />
            {/* <FilterPanel visible={true} /> */}
            <HeaderFilter visible={false} />
            {/* <GroupPanel visible={true} /> */}
            <Scrolling mode="virtual" />
            <Editing
              mode="row"
              allowAdding={false}
              allowDeleting={false}
              allowUpdating={false}
            />
            <Grouping autoExpandAll={false} />
            {SociosData !== undefined && (
              <Column dataField="service_id" caption="Socio">
                <Lookup
                  dataSource={SociosData}
                  valueExpr="service_id"
                  displayExpr="name"
                />
              </Column>
            )}

            <Column
              dataField="membership_id"
              caption="Membresia"
              dataType="string"
              allowSorting={true}
            ></Column>
            <Column
              dataField="name"
              caption="Nombre"
              dataType="string"
              allowSorting={true}
              allowFiltering={true}
              allowReordering={true}
            ></Column>
            <Column
              dataField="lastname1"
              caption="Primer Apellido"
              dataType="string"
              allowSorting={true}
            ></Column>
            <Column
              dataField="lastname2"
              caption="Segundo Apellido"
              dataType="string"
              allowSorting={true}
            ></Column>
            {/* <Column dataField="service_id" caption="Servicio"></Column> */}
            <Column
              dataField="created_date"
              caption="Fecha de Entrada"
              dataType="datetime"
              format="yyyy/MM/dd HH:mm:ss"
              allowSorting={true}
            ></Column>
          </DataGrid>
        </div>
      </React.Fragment>
    );
  }
}
