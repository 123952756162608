module.exports = {
  usuarios: {
    loadUrl: "https://api.iam-cr.com/api/users",
    insertUrl: "https://api.iam-cr.com/api/users",
    updateUrl: "https://api.iam-cr.com/api/users",
    deleteUrl: "https://api.iam-cr.com/api/users",
    getUserTypesUrl: "https://api.iam-cr.com/api/userTypes",
    getUserUrl: "https://api.iam-cr.com/api/users",
  },
  roles: {
    loadUrl: "https://api.iam-cr.com/api/userTypes",
    insertUrl: "https://api.iam-cr.com/api/userTypes",
    updateUrl: "https://api.iam-cr.com/api/userTypes",
    deleteUrl: "https://api.iam-cr.com/api/userTypes",
  },
  socios: {
    loadUrl: "https://api.iam-cr.com/api/services",
    insertUrl: "https://api.iam-cr.com/api/services",
    updateUrl: "https://api.iam-cr.com/api/services",
    deleteUrl: "https://api.iam-cr.com/api/services",
    getServiceTypesUrl: "https://api.iam-cr.com/api/serviceTypes",
  },
  TipoSocios: {
    loadUrl: "https://api.iam-cr.com/api/serviceTypes",
    insertUrl: "https://api.iam-cr.com/api/serviceTypes",
    updateUrl: "https://api.iam-cr.com/api/serviceTypes",
    deleteUrl: "https://api.iam-cr.com/api/serviceTypes",
  },
  usuariosSocios: {
    loadUrl: "https://api.iam-cr.com/api/users_services",
    insertUrl: "https://api.iam-cr.com/api/users_services",
    updateUrl: "https://api.iam-cr.com/api/users_services",
    deleteUrl: "https://api.iam-cr.com/api/users_services",
  },
  empresas: {
    loadUrl: "https://api.iam-cr.com/api/companies",
    insertUrl: "https://api.iam-cr.com/api/companies",
    updateUrl: "https://api.iam-cr.com/api/companies",
    deleteUrl: "https://api.iam-cr.com/api/companies",
    getCompanyTypesUrl: "https://api.iam-cr.com/api/companyTypes",
  },
  TipoEmpresas: {
    loadUrl: "https://api.iam-cr.com/api/companyTypes",
    insertUrl: "https://api.iam-cr.com/api/companyTypes",
    updateUrl: "https://api.iam-cr.com/api/companyTypes",
    deleteUrl: "https://api.iam-cr.com/api/companyTypes",
  },
  membresias: {
    loadUrl: "https://api.iam-cr.com/api/memberships",
    insertUrl: "https://api.iam-cr.com/api/memberships",
    updateUrl: "https://api.iam-cr.com/api/memberships",
    deleteUrl: "https://api.iam-cr.com/api/memberships",
  },
  entradas: {
    loadUrl: "https://api.iam-cr.com/api/entries",
    RegisterUrl: "https://api.iam-cr.com/api/entries",
    ValidateUrl: "https://api.iam-cr.com/api/validateEntry",
  },
};
