import React from "react";
import "./configuracion-usuarios-socios.scss";
import "devextreme/data/odata/store";
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Scrolling,
  Editing,
  Grouping,
  Lookup,
  RequiredRule,
} from "devextreme-react/data-grid";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { useAuth } from "../../contexts/auth";
import { usuarios, socios, usuariosSocios } from "../../api/api";

export default function ConfiguracionUsuariosServicios() {
  const { user } = useAuth();

  if (user) {
    const dataSource = createStore({
      key: "id",
      loadUrl: usuariosSocios.loadUrl,
      insertUrl: usuariosSocios.insertUrl,
      updateUrl: usuariosSocios.updateUrl,
      deleteUrl: usuariosSocios.deleteUrl,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };
      },
    });

    const UsuariosData = createStore({
      key: "user_id",
      loadUrl: usuarios.loadUrl,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };
      },
    });

    const SociosData = createStore({
      key: "service_id",
      loadUrl: socios.loadUrl,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };
      },
    });

    return (
      <React.Fragment>
        <h2 className={"content-block"}>Usuarios por Socio</h2>

        <div className={"content-block dx-card responsive-paddings"}>
          <DataGrid
            dataSource={dataSource}
            showBorders={true}
            height={600}
            remoteOperations={true}
          >
            {/* <Column dataField="id" caption="ID" dataType="string">
            </Column> */}

            <Column dataField="user_id" caption="Usuario">
              <Lookup
                dataSource={UsuariosData}
                valueExpr="user_id"
                displayExpr="username"
              />
              <RequiredRule message="El campo de Usuario es requerido." />
            </Column>

            <Column dataField="service_id" caption="Servicio">
              <Lookup
                dataSource={SociosData}
                valueExpr="service_id"
                displayExpr="name"
              />
              <RequiredRule message="El campo de Servicio es requerido." />
            </Column>

            <FilterRow visible={true} />
            <HeaderFilter visible={false} />
            {/* <GroupPanel visible={true} /> */}
            <Scrolling mode="virtual" />
            <Editing
              mode="row"
              allowAdding={true}
              allowDeleting={true}
              allowUpdating={true}
            />
            <Grouping autoExpandAll={false} />
          </DataGrid>
        </div>
      </React.Fragment>
    );
  }
}
