import React, { Component } from "react";
import BarcodeReader from "react-barcode-reader";
import "./entradas.scss";
import { entradas } from "../../api/api";

export async function validarEntrada(membership_id) {
  try {
    // Send request
    let Result = await fetch(entradas.ValidateUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({ membership_id: membership_id }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data != null) {
          //console.log(data[0].registrado);
          //return data;

          if (data[0].registrado === 0) {
            //RegistrarEntrada(data);
            //console.log("Entrada registrada correctamente.");
            return " fue registrada correctamente.";
          } else {
            //console.log("Esta membresia ya fue utilizada el dia de hoy.");
            return " ya fue utilizada el dia de hoy.";
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
    return {
      isOk: true,
      data: Result,
    };
  } catch {
    return {
      isOk: false,
      message: "Error",
    };
  }
}

export default class Entradas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: "Escanee la membresia que desea registrar en su establecimiento.",
    };

    this.handleScan = this.handleScan.bind(this);
  }
  async handleScan(data) {
    const resultado = await validarEntrada(data);
    this.setState({
      result: "La Membresia " + data + resultado.data,
    });
  }
  handleError(err) {
    console.error(err);
  }

  render() {
    //console.log(this.state.result);
    return (
      <React.Fragment>
        <h2 className={"content-block"}>Registro de Entradas</h2>
        <div className={"content-block"}>
          <div className={"dx-card responsive-paddings"}>
            <div className={"logos-container"}>
              <img
                // src={process.env.PUBLIC_URL + "/header-logo.png"}
                src={process.env.PUBLIC_URL + "/IAMLogo-small.png"}
                className="logo"
                alt="logo"
              ></img>
            </div>
            <div>
              <BarcodeReader
                onError={this.handleError}
                onScan={this.handleScan}
              />
              <center>
                <p>
                  <strong>
                    <font color="#03a9f4">{this.state.result}</font>
                  </strong>
                </p>
              </center>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
