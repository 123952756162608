import React, { useState, useCallback, useEffect } from "react";
import "./perfil.scss";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
} from "devextreme-react/form";
import { useAuth } from "../../contexts/auth";
import LoadIndicator from "devextreme-react/load-indicator";
import notify from "devextreme/ui/notify";
import { getUser, updateUser } from "../../api/auth";
import { DateBox } from "devextreme-react";

export default function Perfil(props) {
  const [loading, setLoading] = useState(false);

  const [userFromAPI, setUser] = useState(null);

  const { user } = useAuth();

  useEffect(() => {
    let isSubscribed = true;

    // declare the async data fetching function
    const fetchData = async () => {
      // get the data from the api
      const data = await getUser(user.username);
      // set state with the result if `isSubscribed` is true
      if (isSubscribed) {
        setUser({
          ID: data.data.id,
          Nombre: data.data.name,
          PrimerApellido: data.data.lastname1,
          SegundoApellido: data.data.lastname2,
          Usuario: data.data.username,
          Email: data.data.email,
          Telefono: data.data.phone,
          FechNacimiento: data.data.birthdate,
        });
      }
    };

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);

    // cancel any future `setData`
    return () => (isSubscribed = false);
  }, [user.username]);

  const handleInputChange = (event) => {
    setUser({
      ...userFromAPI,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);

      //const id = e.target[0].value;
      //const name = e.target[1].value;
      //const lastName1 = e.target[2].value;
      //const lastName2 = e.target[3].value;
      //const username = e.target[4].value;
      //const email = e.target[5].value;
      //const phone = e.target[6].value;
      //const birthdate = e.target[8].value;
      const name = userFromAPI.Nombre;
      const lastName1 = userFromAPI.PrimerApellido;
      const lastName2 = userFromAPI.SegundoApellido;
      const username = userFromAPI.Usuario;
      const phone = userFromAPI.Telefono;
      const birthdate = userFromAPI.FechNacimiento;
      //console.log("userFromAPI", userFromAPI);
      //console.log(birthdate);
      const body = {
        name: name,
        lastname1: lastName1,
        lastname2: lastName2,
        phone: phone,
        birthdate: birthdate,
      };

      const result = await updateUser(username, body);
      setLoading(false);
      if (result.isOk && result.data !== undefined) {
        notify(result.message, "success", 4000);
      } else {
        notify(result.message, "error", 4000);
      }
    },
    [userFromAPI]
  );

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Perfil</h2>
      <div className={"content-block dx-card responsive-paddings"}>
        {userFromAPI && (
          <form onSubmit={onSubmit}>
            <Form
              id="userForm"
              formData={userFromAPI}
              disabled={loading}
              colCountByScreen={colCountByScreen}
              labelLocation={"top"}
            >
              <Item
                dataField={"ID"}
                editorType={"dxTextBox"}
                editorOptions={IDEditorOptions}
              >
                <Label visible={true} />
              </Item>
              <Item
                dataField={"Nombre"}
                editorType={"dxTextBox"}
                editorOptions={NombreEditorOptions}
                onChange={handleInputChange}
              >
                <RequiredRule message="Nombre es requerido" />
                <Label visible={true} />
              </Item>
              <Item
                dataField={"PrimerApellido"}
                editorType={"dxTextBox"}
                editorOptions={PrimerApellidoEditorOptions}
                onChange={handleInputChange}
              >
                <RequiredRule message="Primer Apellido es requerido" />
                <Label visible={true} />
              </Item>
              <Item
                dataField={"SegundoApellido"}
                editorType={"dxTextBox"}
                editorOptions={SegundoApellidoEditorOptions}
                onChange={handleInputChange}
              >
                <RequiredRule message="Segundo Apellido es requerido" />
                <Label visible={true} />
              </Item>
              <Item
                dataField={"Usuario"}
                editorType={"dxTextBox"}
                editorOptions={UsuarioEditorOptions}
              >
                <Label visible={true} />
              </Item>
              <Item
                dataField={"Email"}
                editorType={"dxTextBox"}
                editorOptions={EmailEditorOptions}
              >
                <Label visible={true} />
              </Item>
              <Item
                dataField={"Telefono"}
                editorType={"dxTextBox"}
                editorOptions={TelefonoEditorOptions}
                onChange={handleInputChange}
              >
                <Label visible={true} />
              </Item>
              <Item
                dataField={"FechNacimiento"}
                editorType={"dxDateBox"}
                editorOptions={FechNacEditorOptions}
                onChange={handleInputChange}
                dateSerializationFormat={"yyyy-MM-dd"}
                useMaskBehavior={true}
                displayFormat={"yyyy-MM-dd"}
              >
                <Label visible={true} />
              </Item>
              <ButtonItem colSpan={2} horizontalAlignment={"center"}>
                <ButtonOptions
                  width={"25%"}
                  type={"default"}
                  useSubmitBehavior={true}
                >
                  <span className="dx-button-text">
                    {loading ? (
                      <LoadIndicator
                        width={"24px"}
                        height={"24px"}
                        visible={true}
                      />
                    ) : (
                      "Guardar"
                    )}
                  </span>
                </ButtonOptions>
              </ButtonItem>
            </Form>
          </form>
        )}
        {!userFromAPI && (
          <div className="text-center p-3">
            <span className="spinner-border spinner-border-lg align-center"></span>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

const colCountByScreen = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 3,
};

const IDEditorOptions = {
  stylingMode: "underline",
  placeholder: "ID",
  readOnly: true,
};

const NombreEditorOptions = {
  stylingMode: "underline",
  placeholder: "Nombre",
  readOnly: false,
};
const PrimerApellidoEditorOptions = {
  stylingMode: "underline",
  placeholder: "Primer Apellido",
  readOnly: false,
};
const SegundoApellidoEditorOptions = {
  stylingMode: "underline",
  placeholder: "Segundo Apellido",
  readOnly: false,
};
const UsuarioEditorOptions = {
  stylingMode: "underline",
  placeholder: "Usuario",
  readOnly: true,
};
const EmailEditorOptions = {
  stylingMode: "underline",
  placeholder: "Email",
  readOnly: true,
};
const TelefonoEditorOptions = {
  stylingMode: "underline",
  placeholder: "Telefono",
  readOnly: false,
};
const FechNacEditorOptions = {
  stylingMode: "underline",
  placeholder: "Fech Nacimiento",
  readOnly: false,
  dateSerializationFormat: "yyyy-MM-dd",
};
