import React from "react";
import "./home.scss";

export default function Home() {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>Inicio</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"logos-container"}>
            <img
              // src={process.env.PUBLIC_URL + "/header-logo.png"}
              src={process.env.PUBLIC_URL + "/IAMLogo-small.png"}
              className="logo"
              alt="logo"
            ></img>
          </div>
          <p>Bienvenidos a IAm.</p>
          <p>
            <span>
              Llega a nuevos clientes y aumenta el número de visitas de los
              existentes. Puedes hacerlos venir casi sin costo alguno. Obtenga
              una ventaja competitiva lo antes posible.
            </span>
          </p>
          <p>
            Todo el proceso para concluir la asociación es simple y puede ser
            tan corto como dos semanas o menos. Debe ofrecer las actividades
            descritas en las categorías anteriores para convertirse en nuestro
            socio. Además, debes cumplir algunas condiciones básicas. Por
            supuesto, firmaremos un contrato juntos que establezca las reglas
            del juego.
          </p>
          <p>
            <span>Las condiciones detalladas están disponibles aquí </span>
            <a
              href="https://js.devexpress.com/documentation/"
              target="_blank"
              rel="noopener noreferrer"
            >
              documentacion en linea
            </a>
            <span> y </span>
            <a
              href="https://js.devexpress.com/Demos/Widgetsgallery/"
              target="_blank"
              rel="noopener noreferrer"
            >
              nuestros demos tecnicos
            </a>
            .
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}
