import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import appInfo from "./app-info";
import routes from "./app-routes";
import { SideNavOuterToolbar as SideNavBarLayout } from "./layouts";
import { Footer } from "./components";
import { useAuth } from "./contexts/auth";

export default function Content() {
  const { user } = useAuth();
  const userService = user.service;
  const userRole = user.user_type;
  var service = "";
  if ((userRole === 1) & (userService === null)) {
    //Administrador
    service = "Administrador";
  } else if ((userRole === 2) & (userService != null)) {
    //Empresa
    service = userService;
  } else if ((userRole === 3) & (userService != null)) {
    //Cliente
    service = userService;
  } else if ((userRole === 4) & (userService != null)) {
    //Usuario
    service = userService;
  } else {
    //Otro
    service = "Otro";
  }
  return (
    <SideNavBarLayout title={appInfo.title + service}>
      <Switch>
        {routes.map(({ path, component }) => (
          <Route exact key={path} path={path} component={component} />
        ))}
        <Redirect to={"/home"} />
      </Switch>
      <Footer>
        Copyright © 2021-{new Date().getFullYear()} {appInfo.title} Inc.
      </Footer>
    </SideNavBarLayout>
  );
}
