import { withNavigationWatcher } from "./contexts/navigation";
import {
  HomePage,
  PerfilPage,
  CambiarClavePage,
  SociosPage,
  EmpresasPage,
  MembresiasPage,
  ConfiguracionUsuariosPage,
  ConfiguracionSociosPage,
  ConfiguracionEmpresasPage,
  ConfiguracionRolesPage,
  ReporteEntradasPage,
  ConfiguracionUsuariosSociosPage,
} from "./pages";
import EntradasPage from "./pages/entradas/entradas";

const routesAdmin = [
  {
    path: "/perfil",
    component: PerfilPage,
  },
  {
    path: "/cambiar-clave",
    component: CambiarClavePage,
  },
  {
    path: "/home",
    component: HomePage,
  },
  {
    path: "/entradas",
    component: EntradasPage,
  },
  {
    path: "/socios",
    component: SociosPage,
  },
  {
    path: "/empresas",
    component: EmpresasPage,
  },
  {
    path: "/membresias",
    component: MembresiasPage,
  },
  {
    path: "/reporte-entradas",
    component: ReporteEntradasPage,
  },
  {
    path: "/configuracion-usuarios",
    component: ConfiguracionUsuariosPage,
  },
  {
    path: "/configuracion-usuarios-socios",
    component: ConfiguracionUsuariosSociosPage,
  },
  {
    path: "/configuracion-roles",
    component: ConfiguracionRolesPage,
  },
  {
    path: "/configuracion-socios",
    component: ConfiguracionSociosPage,
  },
  {
    path: "/configuracion-empresas",
    component: ConfiguracionEmpresasPage,
  },
];

export default routesAdmin.map((route) => {
  return {
    ...route,
    component: withNavigationWatcher(route.component),
  };
});
