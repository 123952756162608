import React from "react";
import "./socios.scss";
import "devextreme/data/odata/store";
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Scrolling,
  Editing,
  Grouping,
  Lookup,
  EmailRule,
  RequiredRule,
  StringLengthRule,
} from "devextreme-react/data-grid";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { useAuth } from "../../contexts/auth";
import { socios } from "../../api/api";

export default function Empresas() {
  const { user } = useAuth();

  if (user) {
    const dataSource = createStore({
      key: "id",
      loadUrl: socios.loadUrl,
      insertUrl: socios.insertUrl,
      updateUrl: socios.updateUrl,
      deleteUrl: socios.deleteUrl,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };
      },
    });

    const tiposSocioData = createStore({
      key: "id",
      loadUrl: socios.getServiceTypesUrl,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };
      },
    });

    const estadoData = [
      {
        state: 1,
        descripcion: "Activo",
      },
      {
        state: 0,
        descripcion: "Inactivo",
      },
    ];

    return (
      <React.Fragment>
        <h2 className={"content-block"}>Socios</h2>

        <div className={"content-block dx-card responsive-paddings"}>
          <DataGrid
            dataSource={dataSource}
            showBorders={true}
            focusedRowEnabled={true}
            height={600}
            remoteOperations={true}
            columnAutoWidth={true}
            columnHidingEnabled={true}
          >
            <Column
              dataField="id"
              caption="ID"
              dataType="string"
              hidingPriority={7}
            >
              <RequiredRule message="El campo de ID es requerido." />
            </Column>
            <Column
              dataField="name"
              caption="Nombre"
              dataType="string"
              hidingPriority={6}
            >
              <StringLengthRule
                max={50}
                message="El campo Nombre debe de tener un maximo de 50 caracteres."
              />
              <RequiredRule message="El campo de Nombre es requerido." />
            </Column>
            <Column dataField="email" caption="Email" hidingPriority={2}>
              <EmailRule />
              <RequiredRule message="El campo de Email es requerido." />
            </Column>
            <Column
              dataField="phone"
              caption="Telefono"
              dataType="string"
              hidingPriority={1}
            >
              <RequiredRule message="El campo de Telefono es requerido." />
            </Column>
            <Column dataField="service_type" caption="Tipo" hidingPriority={5}>
              <Lookup
                dataSource={tiposSocioData}
                valueExpr="id"
                displayExpr="description"
              />
              <RequiredRule message="El campo de Tipo es requerido." />
            </Column>
            <Column
              dataField="representative"
              caption="Representante"
              hidingPriority={4}
            >
              <RequiredRule message="El campo de Representante es requerido." />
            </Column>
            <Column dataField="state" caption="Estado" hidingPriority={3}>
              <Lookup
                dataSource={estadoData}
                valueExpr="state"
                displayExpr="descripcion"
              />
              <RequiredRule message="El campo de Estado es requerido." />
            </Column>
            <FilterRow visible={true} />
            <HeaderFilter visible={false} />
            {/* <GroupPanel visible={true} /> */}
            <Scrolling mode="virtual" />
            <Editing
              mode="row"
              allowAdding={true}
              allowDeleting={true}
              allowUpdating={true}
            />
            <Grouping autoExpandAll={false} />
          </DataGrid>
        </div>
      </React.Fragment>
    );
  }
}
