export const navigation = [
  {
    text: "Inicio",
    path: "/home",
    icon: "home",
  },
  {
    text: "Entradas",
    path: "/entradas",
    icon: "check",
  },
  {
    text: "Socios",
    path: "/socios",
    icon: "runner",
  },
  {
    text: "Empresas",
    path: "/empresas",
    icon: "box",
  },
  {
    text: "Membresias",
    path: "/membresias",
    icon: "card",
  },
  {
    text: "Reportes",
    icon: "verticalaligntop",
    items: [
      {
        text: "Entradas",
        path: "/reporte-entradas",
      },
    ],
  },
  {
    text: "Configuración",
    icon: "preferences",
    items: [
      {
        text: "Usuarios",
        path: "/configuracion-usuarios",
      },
      {
        text: "Roles",
        path: "/configuracion-roles",
      },
      {
        text: "Tipos Socios",
        path: "/configuracion-socios",
      },
      {
        text: "Tipos Empresa",
        path: "/configuracion-empresas",
      },
    ],
  },
];

export const navigationServices = [
  {
    text: "Inicio",
    path: "/home",
    icon: "home",
  },
  {
    text: "Entradas",
    path: "/entradas",
    icon: "check",
  },
  {
    text: "Reportes",
    icon: "verticalaligntop",
    items: [
      {
        text: "Entradas",
        path: "/reporte-entradas",
      },
    ],
  },
];

export const navigationCompany = [
  {
    text: "Inicio",
    path: "/home",
    icon: "home",
  },
  {
    text: "Entradas",
    path: "/entradas",
    icon: "check",
  },
  {
    text: "Reportes",
    path: "/reportes",
    icon: "verticalaligntop",
    items: [
      {
        text: "Entradas",
        path: "/reporte-entradas",
      },
    ],
  },
];

export const navigationCustomer = [
  {
    text: "Inicio",
    path: "/home",
    icon: "home",
  },
  {
    text: "Entradas",
    path: "/entradas",
    icon: "check",
  },
  {
    text: "Reportes",
    path: "/reportes",
    icon: "verticalaligntop",
    items: [
      {
        text: "Entradas",
        path: "/reporte-entradas",
      },
    ],
  },
];

export const navigationSocio = [
  {
    text: "Inicio",
    path: "/home",
    icon: "home",
  },
  {
    text: "Entradas",
    path: "/entradas",
    icon: "check",
  },
  {
    text: "Reportes",
    path: "/reportes",
    icon: "verticalaligntop",
    items: [
      {
        text: "Entradas",
        path: "/reporte-entradas",
      },
    ],
  },
];

export const navigationEmpty = [
  {
    text: "Inicio",
    path: "/home",
    icon: "home",
  },
];
