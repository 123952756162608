import React from "react";
import "./configuracion-usuarios.scss";
import "devextreme/data/odata/store";
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Scrolling,
  Editing,
  Grouping,
  Lookup,
  EmailRule,
  RequiredRule,
  StringLengthRule,
} from "devextreme-react/data-grid";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { useAuth } from "../../contexts/auth";
import { usuarios, socios } from "../../api/api";

export default function ConfiguracionUsuarios() {
  const { user } = useAuth();

  if (user) {
    const dataSource = createStore({
      key: "user_id",
      loadUrl: usuarios.loadUrl,
      insertUrl: usuarios.insertUrl,
      updateUrl: usuarios.updateUrl,
      deleteUrl: usuarios.deleteUrl,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };
      },
    });

    const tiposusuarioData = createStore({
      key: "id",
      loadUrl: usuarios.getUserTypesUrl,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };
      },
    });

    const SociosData = createStore({
      key: "service_id",
      loadUrl: socios.loadUrl,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };
      },
    });

    const estadoData = [
      {
        state: 1,
        descripcion: "Activo",
      },
      {
        state: 0,
        descripcion: "Inactivo",
      },
    ];

    return (
      <React.Fragment>
        <h2 className={"content-block"}>Usuarios</h2>

        <div className={"content-block dx-card responsive-paddings"}>
          <DataGrid
            dataSource={dataSource}
            showBorders={true}
            height={600}
            remoteOperations={true}
            focusedRowEnabled={true}
            columnAutoWidth={true}
            columnHidingEnabled={true}
          >
            <Column
              dataField="id"
              caption="ID"
              dataType="string"
              hidingPriority={11}
            >
              <RequiredRule message="El campo de ID es requerido." />
            </Column>

            <Column
              dataField="username"
              caption="Usuario"
              dataType="string"
              hidingPriority={10}
            >
              <StringLengthRule
                max={50}
                message="El campo Usuario debe de tener un maximo de 50 caracteres."
              />
              <RequiredRule message="El campo de Usuario es requerido." />
            </Column>

            <Column
              dataField="name"
              caption="Nombre"
              dataType="string"
              hidingPriority={9}
            >
              <StringLengthRule
                max={50}
                message="El campo Nombre debe de tener un maximo de 50 caracteres."
              />
              <RequiredRule message="El campo de Nombre es requerido." />
            </Column>

            <Column
              dataField="lastname1"
              caption="Primer Apellido"
              dataType="string"
              hidingPriority={8}
            >
              <StringLengthRule
                max={50}
                message="El campo Primer Apellido debe de tener un maximo de 50 caracteres."
              />
              <RequiredRule message="El campo de Primer Apellido es requerido." />
            </Column>

            <Column
              dataField="lastname2"
              caption="Segundo Apellido"
              dataType="string"
              hidingPriority={7}
            >
              <StringLengthRule
                max={50}
                message="El campo Segundo Apellido debe de tener un maximo de 50 caracteres."
              />
              <RequiredRule message="El campo de Segundo Apellido es requerido." />
            </Column>

            <Column dataField="email" caption="Email" hidingPriority={6}>
              <EmailRule />
              <RequiredRule message="El campo de Email es requerido." />
            </Column>

            <Column
              dataField="phone"
              caption="Telefono"
              dataType="string"
              hidingPriority={2}
            >
              <RequiredRule message="El campo de Telefono es requerido." />
            </Column>

            <Column
              dataField="birthdate"
              caption="Fecha de Nacimiento"
              dataType="date"
              hidingPriority={1}
            >
              <RequiredRule message="El campo de Fecha de Nacimiento es requerido." />
            </Column>

            <Column dataField="service_id" caption="Socio" hidingPriority={5}>
              <Lookup
                dataSource={SociosData}
                valueExpr="service_id"
                displayExpr="name"
              />
            </Column>

            <Column dataField="user_type" caption="Rol" hidingPriority={4}>
              <Lookup
                dataSource={tiposusuarioData}
                valueExpr="id"
                displayExpr="description"
              />
              <RequiredRule message="El campo de Rol es requerido." />
            </Column>

            <Column dataField="state" caption="Estado" hidingPriority={3}>
              <Lookup
                dataSource={estadoData}
                valueExpr="state"
                displayExpr="descripcion"
              />
              <RequiredRule message="El campo de Estado es requerido." />
            </Column>

            <FilterRow visible={true} />
            <HeaderFilter visible={false} />
            {/* <GroupPanel visible={true} /> */}
            <Scrolling mode="virtual" />
            <Editing
              mode="row"
              allowAdding={true}
              allowDeleting={true}
              allowUpdating={true}
            />
            <Grouping autoExpandAll={false} />
          </DataGrid>
        </div>
      </React.Fragment>
    );
  }
}
