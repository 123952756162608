import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { SingleCard } from "./layouts";
import {
  LoginForm,
  ResetPasswordForm,
  ChangePasswordForm,
  CreateAccountForm,
} from "./components";

import "./UnauthenticatedContent.scss";

export default function UnauthenticatedContent() {
  return (
    <div className={"background"}>
      <Switch>
        <Route exact path="/login">
          <SingleCard title="Iniciar Sesión">
            <div className={"logos-container"}>
              <img
                src={process.env.PUBLIC_URL + "/IAMLogo-small.png"}
                className="logo"
                alt="logo"
              ></img>
            </div>
            <LoginForm />
          </SingleCard>
        </Route>
        <Route exact path="/crear-cuenta">
          <SingleCard title="Registrarse">
            <CreateAccountForm />
          </SingleCard>
        </Route>
        <Route exact path="/restablecer-clave">
          <SingleCard
            title="Restablecer contraseña"
            description="Ingrese su correo electrónico y le enviaremos un enlace para restablecer su contraseña por correo electrónico."
          >
            <ResetPasswordForm />
          </SingleCard>
        </Route>
        <Route exact path="/actualizar-clave/:token">
          <SingleCard title="Actualizar contraseña">
            <ChangePasswordForm />
          </SingleCard>
        </Route>
        <Redirect to={"/login"} />
      </Switch>
    </div>
  );
}
