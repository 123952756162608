import React, { useState, useCallback, useRef } from "react";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
} from "devextreme-react/form";
import { useAuth } from "../../contexts/auth";
import LoadIndicator from "devextreme-react/load-indicator";
import notify from "devextreme/ui/notify";
import { changePasswordFromProfile } from "../../api/auth";
import "./cambio-clave.scss";

export default function CambiarClave(props) {
  const [loading, setLoading] = useState(false);
  const formData = useRef({});

  const { user } = useAuth();
  //if (user) {
  const Usuario = user.username;

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { password } = formData.current;
      setLoading(true);

      const result = await changePasswordFromProfile(Usuario, password);
      setLoading(false);

      if (result.isOk) {
        notify(result.data.message, "success", 4000);
      } else {
        notify(result.message, "error", 4000);
      }
    },
    [Usuario]
  );

  const confirmPassword = useCallback(
    ({ value }) => value === formData.current.password,
    []
  );

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Cambio de Contraseña</h2>
      <div className={"content-block dx-card responsive-paddings"}>
        <form onSubmit={onSubmit}>
          <Form
            id="userForm"
            colCountByScreen={colCountByScreen}
            labelLocation={"top"}
            formData={formData.current}
            disabled={loading}
          >
            <Item
              dataField={"password"}
              editorType={"dxTextBox"}
              editorOptions={passwordEditorOptions}
            >
              <RequiredRule message="Contraseña es requerida" />
              <Label visible={false} />
            </Item>
            <Item
              dataField={"confirmedPassword"}
              editorType={"dxTextBox"}
              editorOptions={confirmedPasswordEditorOptions}
            >
              <RequiredRule message="Contraseña es requerida" />
              <CustomRule
                message={"Las contraseñas no coinciden"}
                validationCallback={confirmPassword}
              />
              <Label visible={false} />
            </Item>
            <ButtonItem colSpan={2} horizontalAlignment={"center"}>
              <ButtonOptions
                width={"25%"}
                type={"default"}
                useSubmitBehavior={true}
              >
                <span className="dx-button-text">
                  {loading ? (
                    <LoadIndicator
                      width={"24px"}
                      height={"24px"}
                      visible={true}
                    />
                  ) : (
                    "Guardar"
                  )}
                </span>
              </ButtonOptions>
            </ButtonItem>
          </Form>
        </form>
      </div>
    </React.Fragment>
  );
}

const colCountByScreen = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 3,
};

const passwordEditorOptions = {
  //stylingMode: "filled",
  stylingMode: "underline",
  placeholder: "Contraseña",
  mode: "password",
};
const confirmedPasswordEditorOptions = {
  stylingMode: "underline",
  placeholder: "Confirmar Contraseña",
  mode: "password",
};
