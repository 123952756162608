//import defaultUser from "../utils/default-user";
const Cryptr = require("cryptr");
require("dotenv").config();

const setTokenInLocalStore = (token) => {
  localStorage.setItem("token", token);
};

export async function signIn(email, password) {
  try {
    // encrypt password
    const cryptr = new Cryptr(process.env.REACT_APP_SECRET_KEY2);
    let passwordHaash = cryptr.encrypt(password);

    // Send request
    let userResult = await fetch("https://api.iam-cr.com/api/signin", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email, password: passwordHaash }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.user != null) {
          setTokenInLocalStore(data.token);
          return data;
        }
      })
      .catch((error) => {
        console.error(error);
      });

    return {
      isOk: true,
      data: userResult.user,
    };
  } catch {
    return {
      isOk: false,
      message: "Autenticación fallida",
    };
  }
}

export async function createAccount(email, password) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: "No se pudo crear la cuenta",
    };
  }
}

export async function changePassword(password, token) {
  try {
    const cryptr = new Cryptr(process.env.REACT_APP_SECRET_KEY2);
    let passwordHaash = cryptr.encrypt(password);

    let Result = await fetch("https://api.iam-cr.com/api/updatePassword", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ password: passwordHaash, token: token }),
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        if (data.data != null) {
          return data;
        }
      })
      .catch((error) => {
        console.error(error);
      });

    return {
      isOk: true,
      data: Result,
    };
  } catch {
    return {
      isOk: false,
      message: "No se pudo cambiar la contraseña",
    };
  }
}

export async function getUser(username) {
  try {
    let Result = await fetch("https://api.iam-cr.com/api/user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ username: username }),
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log("APIdata", data);
        if (data.user != null) {
          return data;
        }
      })
      .catch((error) => {
        console.error(error);
      });

    return {
      isOk: true,
      data: Result.user,
    };
  } catch {
    return {
      isOk: false,
      message: "No se pudo cargar la informacion del usuario.",
    };
  }
}

export async function updateUser(username, body) {
  try {
    let Result = await fetch("https://api.iam-cr.com/api/user", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ username: username, body: body }),
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        if (data != null) {
          return data;
        }
      })
      .catch((error) => {
        console.error(error);
      });

    return {
      isOk: true,
      data: Result,
      message: "Usuario actualizado exitosamente",
    };
  } catch {
    return {
      isOk: false,
      message: "No se pudo actualizar la informacion",
    };
  }
}

export async function changePasswordFromProfile(username, password) {
  try {
    const cryptr = new Cryptr(process.env.REACT_APP_SECRET_KEY2);
    let passwordHaash = cryptr.encrypt(password);
    const newBody = {
      password: passwordHaash,
    };
    let Result = await fetch(
      "https://api.iam-cr.com/api/updatePasswordFromProfile",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ username: username, body: newBody }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        if (data != null) {
          return data;
        }
      })
      .catch((error) => {
        console.error(error);
      });

    return {
      isOk: true,
      data: Result,
      message: "Contraseña actualizada exitosamente",
    };
  } catch {
    return {
      isOk: false,
      message: "No se pudo actualizar la contraseña",
    };
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    let Result = await fetch("https://api.iam-cr.com/api/resetPassword", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.data != null) {
          return data;
        }
      })
      .catch((error) => {
        console.error(error);
      });

    return {
      isOk: true,
      data: Result,
    };
  } catch {
    return {
      isOk: false,
      message: "No se pudo restablecer la contraseña",
    };
  }
}
