import React from "react";
import "./membresias.scss";
import "devextreme/data/odata/store";
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Scrolling,
  Editing,
  Grouping,
  Lookup,
  EmailRule,
  RequiredRule,
  StringLengthRule,
} from "devextreme-react/data-grid";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { useAuth } from "../../contexts/auth";
import { membresias, empresas } from "../../api/api";

export default function Membresias() {
  const { user } = useAuth();

  if (user) {
    const dataSource = createStore({
      key: "membership_id",
      loadUrl: membresias.loadUrl,
      insertUrl: membresias.insertUrl,
      updateUrl: membresias.updateUrl,
      deleteUrl: membresias.deleteUrl,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };
      },
    });

    const empresasData = createStore({
      key: "id",
      loadUrl: empresas.loadUrl,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };
      },
    });

    const estadoData = [
      {
        state: 1,
        descripcion: "Activo",
      },
      {
        state: 0,
        descripcion: "Inactivo",
      },
    ];

    return (
      <React.Fragment>
        <h2 className={"content-block"}>Membresias</h2>

        <div className={"content-block dx-card responsive-paddings"}>
          <DataGrid
            dataSource={dataSource}
            showBorders={true}
            focusedRowEnabled={true}
            height={600}
            remoteOperations={true}
            columnAutoWidth={true}
            columnHidingEnabled={true}
          >
            {/*   <Column
              dataField="membership_id"
              caption="# Membresia"
              dataType="string"
            >
              <RequiredRule message="El campo de # de Membresia es requerido." />
            </Column> */}

            <Column
              dataField="id"
              caption="ID"
              dataType="string"
              hidingPriority={8}
            >
              <RequiredRule message="El campo de ID es requerido." />
            </Column>

            <Column
              dataField="name"
              caption="Nombre"
              dataType="string"
              hidingPriority={7}
            >
              <StringLengthRule
                max={50}
                message="El campo Nombre debe de tener un maximo de 50 caracteres."
              />
              <RequiredRule message="El campo de Nombre es requerido." />
            </Column>

            <Column
              dataField="lastname1"
              caption="Primer Apellido"
              dataType="string"
              hidingPriority={6}
            >
              <StringLengthRule
                max={50}
                message="El campo Primer Apellido debe de tener un maximo de 50 caracteres."
              />
              <RequiredRule message="El campo de Primer Apellido es requerido." />
            </Column>

            <Column
              dataField="lastname2"
              caption="Segundo Apellido"
              dataType="string"
              hidingPriority={5}
            >
              <StringLengthRule
                max={50}
                message="El campo Segundo Apellido debe de tener un maximo de 50 caracteres."
              />
              <RequiredRule message="El campo de Segundo Apellido es requerido." />
            </Column>

            <Column dataField="company_id" caption="Empresa" hidingPriority={4}>
              <Lookup
                dataSource={empresasData}
                valueExpr="id"
                displayExpr="name"
              />
              <RequiredRule message="El campo de Empresa es requerido." />
            </Column>

            <Column dataField="email" caption="Email" hidingPriority={2}>
              <EmailRule />
              <RequiredRule message="El campo de Email es requerido." />
            </Column>

            <Column
              dataField="phone"
              caption="Telefono"
              dataType="string"
              hidingPriority={1}
            >
              <RequiredRule message="El campo de Telefono es requerido." />
            </Column>

            <Column dataField="state" caption="Estado" hidingPriority={3}>
              <Lookup
                dataSource={estadoData}
                valueExpr="state"
                displayExpr="descripcion"
              />
              <RequiredRule message="El campo de Estado es requerido." />
            </Column>
            <FilterRow visible={true} />
            <HeaderFilter visible={false} />
            {/* <GroupPanel visible={true} /> */}
            <Scrolling mode="virtual" />
            <Editing
              mode="row"
              allowAdding={true}
              allowDeleting={true}
              allowUpdating={true}
            />
            <Grouping autoExpandAll={false} />
          </DataGrid>
        </div>
      </React.Fragment>
    );
  }
}
