import React from "react";
import "./configuracion-empresas.scss";
import "devextreme/data/odata/store";
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Scrolling,
  Editing,
  Grouping,
  RequiredRule,
  StringLengthRule,
} from "devextreme-react/data-grid";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { useAuth } from "../../contexts/auth";
import { TipoEmpresas } from "../../api/api";

export default function ConfigurationTipoEmpresas() {
  const { user } = useAuth();

  if (user) {
    const dataSource = createStore({
      key: "id",
      loadUrl: TipoEmpresas.loadUrl,
      insertUrl: TipoEmpresas.insertUrl,
      updateUrl: TipoEmpresas.updateUrl,
      deleteUrl: TipoEmpresas.deleteUrl,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };
      },
    });

    return (
      <React.Fragment>
        <h2 className={"content-block"}>Tipos de Empresa</h2>

        <div className={"content-block dx-card responsive-paddings"}>
          <DataGrid
            dataSource={dataSource}
            showBorders={true}
            height={600}
            remoteOperations={true}
          >
            {/*  <Column dataField="id" caption="ID" dataType="string">
              <RequiredRule message="El campo de ID es requerido." />
            </Column> */}

            <Column dataField="description" caption="Tipo" dataType="string">
              <StringLengthRule
                max={50}
                message="El campo Tipo debe de tener un maximo de 50 caracteres."
              />
              <RequiredRule message="El campo de Tipo es requerido." />
            </Column>

            <FilterRow visible={true} />
            <HeaderFilter visible={false} />
            {/* <GroupPanel visible={true} /> */}
            <Scrolling mode="virtual" />
            <Editing
              mode="row"
              allowAdding={true}
              allowDeleting={true}
              allowUpdating={true}
            />
            <Grouping autoExpandAll={false} />
          </DataGrid>
        </div>
      </React.Fragment>
    );
  }
}
